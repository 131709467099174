import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
import Rating from '../components/Rating'
import products from '../products'

const ProductScreen = () => {
  const { id } = useParams()
  const product = products.find((p) => p._id === id)

  return (
    <div className="screenD container">
      <Link className='btn btn-outline-primary my-3' to='/'>
        Go Back
      </Link>
      <Row>
        <Col md={6}>
          <Image src={product.image} alt={product.name} fluid />
        </Col>
        <Col md={6}>
          <Row  style={{gap:30}}>
            <div>
          <Card>
            <ListGroup variant='flush' className="aligntitle">
              <ListGroup.Item
                style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
              >
                <Row>
                  <Col>Price:  <strong>${product.price}</strong></Col>
            
                </Row>
              </ListGroup.Item>

              <ListGroup.Item
                style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
              >
                <Row>
                  <Col>Status: {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}</Col>
                 
                </Row>
              </ListGroup.Item>

              <ListGroup.Item
                style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
              >
                <Button
                  className='btn-block'
                  type='button'
                  disabled={product.countInStock === 0}
                >
                  Buy
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
          </div>
          <div>
          <ListGroup variant='flush' className="card">
            <ListGroup.Item
              style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
            >
              <h2>{product.name}</h2>
            </ListGroup.Item>
            <ListGroup.Item
              style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
            >
              <Rating
                value={product.rating}
                text={`${product.numReviews} reviews`}
              />
            </ListGroup.Item>
            <ListGroup.Item
              style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
            >
              Price: ${product.price}
            </ListGroup.Item>
            <ListGroup.Item
              style={{ color: '#17141f', backgroundColor: '#faf9fc' }}
            >
              Description: {product.description}
            </ListGroup.Item>
          </ListGroup>
          </div>
          </Row>
        

        </Col>
       
        
      </Row>
    </div>
  )
}

export default ProductScreen
